.Mention {
    display: flex;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Mention .Societe {
    display: flex;
    width: 50%;
    color: white;
    align-items: center;
}