.information-home {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.information-home div {
    display: flex;
    width: 60%;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    margin-bottom: 1.5rem;
    box-shadow: 0 0 10px rgb(255, 255, 255);
    border: none;
    border-radius: 25px;
    padding: 1rem 1rem;
}

.information-home div h3{
    font-size: 1.5rem;
}

.information-home div p{
    font-size: 1.2rem;
    margin-top: 1rem;
    text-align: center;
}

@media only screen and (max-width: 1024px){
    .information-home div h3{
        font-size: 1.2rem;
    }
    
    .information-home div p{
        font-size: 1rem;
        margin-top: 0.7rem;
    }
}