.sigle-container{
    display: flex;
    /* align-items: end; */
    /* justify-content: end; */
    width: 95%;
}

.sigle-container img{
    position: absolute;
    top: 80px;
    right: 2%;
    width: 80px;
    height: 80px;
}
