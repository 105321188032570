.modal {
    display: none; 
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgba(0,0,0,0.4); 
    padding-top: 60px;
  }
  
  .modal.show {
    display: block; 
  }

  .modal-content {
    background: radial-gradient(ellipse at top, #b700ff, #b700ff 40%, #011bff 100%),
                radial-gradient(ellipse at bottom, #011bff, #011bff 40%, #b700ff 100%);
    margin: 5% auto; 
    padding: 20px;
    border: 1px solid #888;
    width: 50%; 
    border-radius: 25px;
  }
  
  .close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close-button:hover,
  .close-button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
@media only screen and (max-width: 1024px){
  .modal-content {
    width: 70%; 
  }
}