@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Shadows+Into+Light&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=Dancing+Script:wght@400..700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Shadows+Into+Light&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=Dancing+Script:wght@400..700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Shadows+Into+Light&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Tilt+Neon&display=swap');
@import url('https://fonts.cdnfonts.com/css/trebuchet-ms-2');

.qui-nous-sommes{
    display: flex;
    width: 100%;
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.qui-nous-sommes a {
    text-decoration: none;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    font-family: "Tilt Neon", sans-serif;
    background: linear-gradient(217deg, rgb(243, 0, 223), rgb(0, 195, 255) 70.71%),
                linear-gradient(127deg, rgb(68, 0, 255), rgb(206, 8, 255) 70.71%),
                linear-gradient(336deg, rgb(28, 232, 247), rgba(204, 0, 255, 0) 70.71%);
    box-shadow: 0 0 20px rgb(0, 183, 255), 0 0 10px rgb(0, 183, 255);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 15px;
    margin-right: 1rem;
    margin-left: 1rem;
}

.qui-nous-sommes a:hover {
    text-shadow: 0 0 5px #ffffff, 0 0 20px #ffffff;
}

.container {
    position: relative;
}

.container .wrapper{
    width: 80%;
    height: 500px;
    margin: 3rem auto;
    overflow: hidden;
}

.container .wrapper-holder{
    display: grid;
    grid-template-columns: repeat(2, 100%);
    width: 100%;
    height: 100%;
    animation: slider 10s ease-in-out infinite alternate;
}

.container #slider-img-1{
    background-image: url('../assets/accueil_transp.webp');
    background-size: 100% 100%;
}

.container #slider-img-2{
    background-image: url('../assets/38.webp');
    background-size: 100% 100%;
}

.container .button-holder .button {
    background-color: white;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    display: inline-block;
    margin: 3rem;
}

.container .button-holder{
    position: absolute;
    left: 45%;
    bottom: 0%;
}

.button-holder a{
    color: transparent;
}

.button:hover{
    box-shadow: 0px 0px 10px, 10px, rgba(0, 255, 255, 0.6);
}

.video_container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    height: 500px;
    width: 100%;
}

.video_container video{
    width: auto;
    height: 100%;
}

.description-container {
    display: flex;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.description-home {
    display: flex;
    height: auto;
    width: 80%;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    margin-top: 1rem
}

.description-home h3 {
    font-family: "Tilt Neon", sans-serif;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: bold;
}

.description-home p {
    text-align: center;
    font-size: 1.4rem;
    font-family: "Tilt Neon", sans-serif;
}
.description-home li {
    font-size: 1.4rem;
    font-family: "Tilt Neon", sans-serif;
}


.image-container-home {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.image-container-home img {
    display: flex;
    width: 70%;
}

@keyframes slider{
    0%{transform: translateX(0%);}
    10%{transform: translateX(0%);}
    20%{transform: translateX(0%);}
    30%{transform: translateX(-100%);}
    40%{transform: translateX(-100%);}
    50%{transform: translateX(-100%);}
    60%{transform: translateX(-100%);}
    70%{transform: translateX(-100%);}
    80%{transform: translateX(-100%);}
    90%{transform: translateX(0%);}
    100%{transform: translateX(0%);}
}

@media only screen and (max-width: 1024px){
    .qui-nous-sommes a {
        font-size: 0.8rem;
    }
    .container .wrapper{
        width: 100%;
        height: 150px;
    }
    .container .button-holder{
        position: absolute;
        left: 42%;
        bottom: 0%;
    }
    .container .button-holder .button {
        width: 10px;
        height: 10px;
        margin: 1rem;
    }
    .qui-nous-sommes{
        margin-bottom: 1rem;
        height: auto;
    }
    .video_container{
        height: 300px;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    .description-home h3 {
        font-size: 1rem;
    }
    .description-home p {
        font-size: 0.9rem;
    }
    .description-home li {
        font-size: 0.9rem;
    }
    .image-container-home img {
        width: 100%;
    }
}