.formulaire-perso{
    display: flex;
    height: 400px;
    background: radial-gradient(ellipse at top, #b700ff, transparent),
                radial-gradient(ellipse at bottom, #011bff, transparent);
    flex-direction: column;
    margin: 0 2rem;
    border-radius: 25px;
    margin-top: 2rem;
}

.form-messega-pers{
    display: flex;
    width: 100%;
    height: 50%;
}

.form-messega-pers textarea{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 25px;
    padding: 1rem;
    font-size: 1rem;
    resize: none;
    background: transparent;
    color: white;
}

.form-prm-1{
    display: flex;
    width: 100%;
    height: 12%;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
}

.form-prm-1 input{
    width: 20%;
    height: 100%;
    border: none;
    border-radius: 25px;
    padding: 1rem;
    font-size: 1rem;
    background: transparent;
    border-color: white;
    color: white;
    border-style: solid;
    box-shadow: 0 0 20px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255);
}

.form-prm-2{
    display: flex;
    width: 100%;
    height: 12%;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
}

.form-prm-2 input{
    width: 20%;
    height: 100%;
    border: none;
    border-radius: 25px;
    padding: 1rem;
    font-size: 1rem;
    color: white;
    background: transparent;
    border-color: white;
    border-style: solid;
    box-shadow: 0 0 20px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255);
}

.form-prm-3{
    display: flex;
    width: 100%;
    height: 12%;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
}

.form-prm-3 button{
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    width: 10%;
    border-color: white;
    padding: 0.5rem;
    color: white;
    border-radius: 25px;
    border-style: solid;
    box-shadow: 0 0 20px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255);
    font-size: 1rem;
}

.form-prm-3 button:hover{
    text-shadow: 0 0 5px white, 0 0 10px white;
}

@media only screen and (max-width: 1024px){
    .formulaire-perso{
        height: 600px;
    }

    .form-prm-1{
        flex-direction: column;
    }
    .form-prm-1 input{
        width: 50%;
        margin-top: 0.5rem;
        font-size: 0.8rem;
    }
    
    .form-prm-2{
        flex-direction: column;
        margin-top: 4rem;
    }
    .form-prm-2 input{
        width: 50%;
        margin-top: 0.5rem;
        font-size: 0.8rem;
    }
    .form-prm-3{
        flex-direction: column;
    }
    .form-prm-3 input{
        width: 50%;
        margin-top: 0.5rem;
    }
    .form-prm-3 button{
        margin-top: 0.5rem;
        width: 30%;
        font-size: 0.8rem;
    }
}