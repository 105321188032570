footer {
    display: flex;
    width: 100%;
    height: 80px;
    background: linear-gradient(217deg, rgb(243, 0, 223), rgb(0, 195, 255) 70.71%),
                linear-gradient(127deg, rgb(68, 0, 255), rgb(206, 8, 255) 70.71%),
                linear-gradient(336deg, rgb(28, 232, 247), rgba(204, 0, 255, 0) 70.71%);
    justify-content: center;
    align-items: center;
    font-family: "Tilt Neon", sans-serif;
    font-size: 1.2rem;
    margin-top: 1rem;
}

footer div p {
    text-align: center;
    color: white;
}

footer div p a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}

footer div p a:hover{
    text-shadow: 0 0 5px #ffffff, 0 0 10px #ffffff;
}

@media only screen and (max-width: 1024px){
    footer {
        font-size: 1rem;
    }
}