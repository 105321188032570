.Title {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    font-size: 3rem;
    text-shadow: 0 0 5px magenta, 0 0 10px magenta;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
}

@media only screen and (max-width: 1024px){
    .Title{
        font-size: 1.5rem;
        margin-bottom: 0rem;
    }
}