@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Shadows+Into+Light&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=Dancing+Script:wght@400..700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Shadows+Into+Light&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Caveat:wght@400..700&family=Dancing+Script:wght@400..700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Shadows+Into+Light&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Tilt+Neon&display=swap');
@import url('https://fonts.cdnfonts.com/css/trebuchet-ms-2');

div .render-néon{
    display: flex;
    align-items: center;
    width: 100%;
    color: white;
    padding: 0 2rem;
    flex-direction: column;
}

.button-devis{
    display: flex;
    width: 70%;
    height: 80px;
    align-items: center;
    justify-content: end;
}

.button-devis button{
    border: none;
    background: transparent;
    padding: 0.5rem;
    border-radius: 15px;
    color: white;
    font-family: "Tilt Neon", sans-serif;
    font-size: 1.3rem;
    box-shadow: 0 0 10px rgb(0, 255, 234);
}

.button-devis button:hover{
    text-shadow: 0 0 5px white, 0 0 10px white;
}

.selected-prm{
    display: flex;
    height: 100px;
    width: 80%;
    flex-direction: row;
    justify-content: center;
}

.selected-prm div{
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 1.6rem;
    margin: 0 1.5rem;
}

.selected-prm div label{
    text-shadow: 0 0 5px white, 0 0 10px white;
    margin-bottom: 0.5rem;
}

.selected-prm div select{
    background: transparent;
    width: auto;
    color: white;
    border-radius: 25px;
    padding-left: 0.5rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-size: 1rem;
    box-shadow: 0 0 10px rgb(0, 255, 234);
    border: none;
}

.selected-prm div select option{
    color: black;
}

.selected-prm div select:hover{
    box-shadow: 0 0 10px rgb(0, 255, 234), 0 0 5px rgb(255, 255, 255);
}

.input-text textarea {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    height: 2.5rem;
    padding-left: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-radius: 15px;
    font-size: 1rem;
    background: transparent;
    color: white;
    box-shadow: 0 0 10px rgb(0, 255, 234);
    border: none;
    resize: none;
}

.back-box{
    display: flex;
    margin-top: 1rem;
    background: url("../assets/back_box.webp");
    width: 70%;
    height: 450px;
    background-size: 100% 500px;
    flex-direction: row;
    border: 1px solid white;
    border-radius: 25px;
    box-shadow: 0 0 50px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255);
}

.back-box .display-height{
    display: flex;
    width: 30%;
    height: 100%;
}

.back-box .display-price{
    display: flex;
    height: 100%;
    width: 10%;
    /* background: maroon; */
    align-items: end;
    padding-bottom: 2%;
    font-size: 2rem;
    font-family: "Tilt Neon", sans-serif;
}

.display-height{
    /* color: black; */
    padding-top: 3%;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    font-size: 1.5rem;
}

.display-height h5{
    color: black;
}

.back-box-2{
    display: flex;
    width: 60%;
    height: 100%;
    flex-direction: column;
}

.back-box-2 .display-width{
    display: flex;
    width: 100%;
    height: 30%;
    /* background: blue; */
}
.display-width{
    flex-direction: column;
    align-items: center;
    padding-right: 35%;
    font-size: 1.5rem;
}

.display-width h5{
    color: black;
}

.back-box-2 .display-res{
    display: flex;
    width: 100%;
    height: 70%;
    padding-top: 23%;
    padding-right: 35%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: green; */
}

.para-containeur{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.display-res p {
    font-size: 3.5rem;
}

.vertical-arrow {
    transform: scaleX(4) scaleY(5);
}
.horizontal-arrow {
    transform: scaleX(10) scaleY(4);
}

.form-modal{
    display: flex;
    width: 100%;
    /* height: 40px; */
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.form-modal input{
    background: transparent;
    color: white;
    border-radius: 25px;
    padding-left: 0.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    margin-right: 1rem;
    font-size: 1.3rem;
    box-shadow: 0 0 10px rgb(0, 255, 234);
    border: none;
}
::placeholder {
    color: white;
    opacity: 1;
}

@media only screen and (max-width: 1024px){
.form-modal{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}
    .form-modal input{
        font-size: 0.8rem;
        margin-bottom: 1rem;
    }
    div .render-néon{
        height: 600px;
    }

    .input-text textarea{
        height: 1.7rem;
        font-size: 0.7rem;
        margin-bottom: 0.8rem;
        margin-top: 0rem;
        padding-top: 0.3rem;
        padding-bottom: 0;
    }

    .back-box{
        width: 85%;
        height: 250px;
        background-size: 100% 250px;
    }

    .button-devis{
        width: 85%;
        height: 80px;
    }
    
    .button-devis button{
        font-size: 1rem;
    }

    .back-box .display-price{
        font-size: 1rem;
    }

    .display-height{
        font-size: 1rem;
    }
    .back-box-2 .display-res{
        height: 80%;
    }
    .back-box-2 .display-width{
        height: 20%;
    }
    .display-width{
        font-size: 1rem;
    }

    .display-res p{
        font-size: 1rem;
    }

    .selected-prm {
        height: 270px;
        font-size: 1.2rem;
        flex-direction: column;
    }

    .selected-prm div{
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .vertical-arrow {
        transform: scaleX(4) scaleY(5);
    }
    .horizontal-arrow {
        transform: scaleX(5) scaleY(3);
    }

    .selected-prm div select{
        font-size: 0.8rem;
    }
}
