@import url('https://fonts.googleapis.com/css2?family=Anta&family=Tilt+Neon&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: url('../assets/backgroud.webp');
    background-size: cover;
    height: 100vh;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
}

:root{
    --textColor: #eee;
}

header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background: linear-gradient(217deg, rgba(243, 0, 223, 0.8), rgba(0, 195, 255, 0.425) 70.71%),
                linear-gradient(127deg, rgba(68, 0, 255, 0.8), rgba(206, 8, 255, 0.048) 70.71%),
                linear-gradient(336deg, rgba(28, 232, 247, 0.836), rgba(204, 0, 255, 0) 70.71%);
    color: var(--textColor);
    font-family: "Tilt Neon", sans-serif;
    font-size: 1.5rem;
}

nav a{
    color: var(--textColor);
    text-decoration: none;
    margin: 0 2rem;
}

nav a:hover{
    text-shadow: 0 0 5px #ffffff, 0 0 10px #ffffff;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

header .logo{
    height: 90%;
    left: 2rem;
    top: 2rem;
}

@media only screen and (max-width: 1024px){
    header .nav-btn{
        visibility: visible;
        opacity: 2;
    }

    header nav{
        position: fixed !important;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background: linear-gradient(217deg, rgb(243, 0, 223), rgba(0, 0, 255, 0) 70.71%),
                    linear-gradient(127deg, rgb(255, 0, 0), rgb(0, 195, 255) 70.71%),
                    linear-gradient(336deg, rgb(0, 195, 255), rgba(0, 0, 255, 0) 70.71%);
        transition: 1s;
        transform: translateY(-100vh);
        z-index: 4;
    }
    
    header .responsive_nav{
        transform: none;
    }

    nav a{
        font-size: 1.5rem;
    }
}
