.image-néons{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

.img-contaner{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
    margin: 0.5rem;
}

.image-néons img {
    width: 500px;
}

.image-néon-link{
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
}

.image-néon-link a {
    text-decoration: none;
    color: white;
    font-family: "Tilt Neon", sans-serif;
    font-size: 2rem;
    font-weight: bold;
    background: linear-gradient(217deg, rgb(243, 0, 223), rgb(0, 195, 255) 70.71%),
                linear-gradient(127deg, rgb(68, 0, 255), rgb(206, 8, 255) 70.71%),
                linear-gradient(336deg, rgb(28, 232, 247), rgba(204, 0, 255, 0) 70.71%);
    box-shadow: 0 0 20px rgb(0, 183, 255), 0 0 10px rgb(0, 183, 255);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 15px;
}

.image-néon-link a:hover {
    text-shadow: 0 0 5px #ffffff, 0 0 20px #ffffff;
}

@media only screen and (max-width: 1024px){
    .image-néons img {
        width: 400px;
    }
}

@media only screen and (max-width: 1024px){
    .image-néon-link a {
        font-size: 1rem;
    }
}