.formulaire {
    display: flex;
    height: 400px;
    margin-top: 5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    background: radial-gradient(ellipse at top, #b700ff, transparent),
                radial-gradient(ellipse at bottom, #011bff, transparent);
    border-radius: 25px;
}

.form-prm {
    display: flex;
    height: 100%;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding-left: 2rem;
}

.form-prm input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 25px;
    background: transparent;
    color: white;
    font-size: 1.5rem;
    border-style: solid;
    border-color: white;
    box-shadow: 0 0 20px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255);
}

.form-send {
    display: flex;
    height: 100%;
    width: 50%;
    flex-direction: column;
}

::placeholder {
    color: white;
    opacity: 1;
  }

.form-send textarea {
    width: 90%;
    height: 300px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 25px;
    resize: none;
    background: transparent;
    color: white;
    border-style: solid;
    border-color: white;
    box-shadow: 0 0 20px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255);
    font-size: 1.5rem;
}

.form-send button {
    width: 25%;
    padding: 0.5rem;
    border-radius: 25px;
    background: transparent;
    color: white;
    border-style: solid;
    border-color: white;
    box-shadow: 0 0 20px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255);
    font-size: 1rem;
}

.form-send button:hover {
    text-shadow: 0 0 5px white, 0 0 10px white;
}

@media only screen and (max-width: 1024px){
    .formulaire{
        margin-top: 2rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 550px;
    }
    .form-prm {
        padding-left: 0rem;
        width: 100%;
        margin-bottom: 0;
        margin-top: 1.5rem;
    }
    .form-prm input {
        width: 90%;
        font-size: 1rem;
    }

    .form-send {
        margin-top: 0;
        width: 100%;
        margin-left: 2rem;
    }
    .form-send button {
        width: 50%;
        margin-bottom: 1rem;
    }
}